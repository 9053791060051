* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background: black;
  min-height: 100%;
  font-family: "Arial", sans-serif;
  margin: 0;
}

.wrap {
  height: 100vh;
  padding: 60px;
}


