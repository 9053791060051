.header {
    background-color: #f2d94e;
    padding: 15px 0;
    text-align: center;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.header h1 {
    font-size: 2em;
    margin: 0;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.header p {
    font-size: 1.1em;
    margin-top: 5px;
    color: #555;
}
