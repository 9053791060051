.modalOverlay {
    background: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.modal {
    position: relative;
    width: 500px;
    height: 400px;
    max-height: 90%;
    max-width: 90%;
    min-height: 380px;
    margin: 0 auto;
    background: #f3f3f3;
    border: 8px solid #f9dc5c;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
    top: 50%;
    transform: translateY(-50%);
    padding: 30px 10px;
    z-index: 9999;
}

.modal .winner {
    font-size: 40px;
    text-align: center;
    font-family: "Anton", sans-serif;
    color: #d33f49;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 480px) {
    .modal .winner {
        font-size: 20px;
    }
}

.modal .restart {
    font-family: "Anton", sans-serif;
    margin: 30px auto;
    padding: 20px 30px;
    display: block;
    font-size: 24px;
    border: none;
    background: #d33f49;
    background: linear-gradient(#d33f49, #8b2323);
    border: 1px solid #8b2323;
    border-radius: 50px;
    color: white;
    text-shadow: 0px 1px 0 black;
    cursor: pointer;
    transition: all 0.3s;
}

.modal .restart:hover {
    background: linear-gradient(#8b2323, #d33f49);
    transform: scale(1.05);
}

.modal .message {
    text-align: center;
}

.modal .message a {
    text-decoration: none;
    color: #28afe6;
    font-weight: bold;
    transition: all 0.3s;
}

.modal .message a:hover {
    color: #56c0eb;
    border-bottom: 1px dotted #56c0eb;
}